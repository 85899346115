import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { HTTP_STATUS_CODES, USER_TOKEN } from '@/constants';

export function interceptSuccessResponse(
  response: AxiosResponse,
): AxiosResponse {
  return response;
}

export function interceptErrorResponse(error: AxiosError): Promise<never> {
  if (!error.response?.data) {
    return Promise.reject(error.message);
  }

  if (error.response.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
    localStorage.removeItem(USER_TOKEN);
    window.location.replace('/');
  }

  return Promise.reject(error.response.data);
}

export function interceptConfigRequest(
  config: InternalAxiosRequestConfig<unknown>,
): InternalAxiosRequestConfig<unknown> {
  return config;
}

export function interceptErrorRequest(error: AxiosError): Promise<never> {
  return Promise.reject(error);
}
