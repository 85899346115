import styled from 'styled-components';

const FormGroup = styled.div`
  padding: 8px 0px;

  .ant-select-single,
  .ant-upload {
    width: 100%;
  }

  .ant-upload-button {
    text-align: left !important;
  }

  .ant-input-additional {
    margin-top: 16px;
  }
`;

export default FormGroup;
