import styled from 'styled-components';

const Avatar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.green2};
  border-radius: 50%;
  color: ${({ theme }) => theme.color.white};
  display: inline-flex;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 24px;
  width: 40px;
`;

export default Avatar;
