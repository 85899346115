import { get } from 'lodash';

import { Annotation, Option, AnnotationBody } from '@/models';
import { vibrioObjects } from '@/constants';

interface WidgetAnnotatorArgs {
  annotation: Annotation;
  onAppendBody(annotator: Partial<AnnotationBody>): void;
  onUpdateBody(
    value?: Partial<AnnotationBody> | null,
    annotator?: Partial<AnnotationBody>,
  ): void;
}

const WidgetAnnotator = (args: WidgetAnnotatorArgs) => {
  const { annotation, onUpdateBody, onAppendBody } = args;

  const currentSelectBody = annotation
    ? annotation.bodies.find(({ purpose }) => purpose === 'highlighting')
    : null;

  const currentSelectValue = currentSelectBody ? currentSelectBody.value : null;

  const addTag = (event: Event) => {
    const target = event.target as HTMLButtonElement;
    if (currentSelectBody) {
      onUpdateBody(currentSelectBody, {
        type: 'TextualBody',
        purpose: 'highlighting',
        value: target.value,
      });
    } else {
      onAppendBody({
        type: 'TextualBody',
        purpose: 'highlighting',
        value: target.value,
      });
    }
  };

  const createOptions = (options: Option[]) => {
    let html$ = '<option value="">Pilih Objek</option>';

    options.forEach((option) => {
      if (option.value === currentSelectValue) {
        html$ += `<option value="${option.value}" selected>${option.label}</option>`;
      } else {
        html$ += `<option value="${option.value}">${option.label}</option>`;
      }
    });

    return html$;
  };

  const createSelect = () => {
    const select = document.createElement('select');
    select.className = 'form-select';
    select.addEventListener('change', addTag);

    let html$ = '';
    const parent = get(args, 'parent', false);

    if (!parent) {
      html$ = createOptions(vibrioObjects);
    } else {
      const parentBody = annotation.bodies.find(
        ({ purpose }) => purpose === parent,
      );

      if (parentBody && parentBody.value) {
        const choices = get(args, `options.${parentBody.value}`, []);
        html$ = createOptions(choices);
      } else {
        html$ = createOptions([]);
      }
    }
    select.innerHTML = html$;
    return select;
  };

  const container = document.createElement('div');

  container.className = 'select-widget';
  container.appendChild(createSelect());

  return container;
};

export default WidgetAnnotator;
