import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: 16px;
  }
`;

function Container({ children }: PropsWithChildren) {
  return <StyledContainer>{children}</StyledContainer>;
}

export default Container;
