import { Table } from 'antd';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  // Mobile and Tablet
  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    .ant-table-container {
      table {
        table-layout: unset !important;
      }
    }

    .ant-table-content {
      overflow-x: auto;
    }
  }

  .ant-btn-link {
    border: 0;
    color: ${({ theme }) => theme.color.primary};
    font-size: 12px;
    font-weight: 600;
    height: 18px;
    padding-top: 0;
    padding-bottom: 0;

    &:hover {
      color: ${({ theme }) => theme.color.successPrimary};
    }
  }

  .ant-table {
    font-size: 12px;
    overflow-x: auto;

    table {
      border-collapse: separate;
      border-spacing: 0 4px;
    }

    .ant-table-expanded-row {
      .ant-table-cell {
        background-color: transparent;
      }
    }

    .ant-table-cell {
      border-bottom: 0;
      font-weight: 400;
      line-height: 18px;
      padding: 12px;

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }

      &::before {
        content: none !important;
      }
    }

    .ant-table-thead {
      background-color: ${({ theme }) => theme.color.white4};

      .ant-table-cell {
        color: ${({ theme }) => theme.color.black1};
      }
    }

    .ant-table-tbody {
      &::before {
        content: '@';
        display: block;
        line-height: 1px;
        text-indent: -99999px;
      }

      .ant-table-row {
        background-color: ${({ theme }) => theme.color.white4};

        .ant-table-cell {
          border: 0 !important;
        }
      }
    }
  }

  .ant-table-placeholder {
    .ant-table-cell {
      border: 0 !important;
    }
  }
`;

export default StyledTable;
