/* eslint-disable react/require-default-props */
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title: string;
  description?: string;
}

function SEO({
  title,
  description = 'Welcome to Cultivation team internal tools',
}: SEOProps) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
}

export default memo(SEO);
