import { useRef } from 'react';

export interface LocalStorageSet {
  key: string;
  value: unknown;
  isEncode?: boolean;
}

export interface LocalStorageHook {
  get<T = unknown>(key: string, isDecode?: boolean): T | null;
  isExist(key: string): boolean;
  remove(key: string): void;
  reset(): void;
  set(storage: LocalStorageSet): void;
}

export default function useLocalStorage(): LocalStorageHook {
  function get<T = unknown>(key: string, isDecode = false): T | null {
    const data = localStorage.getItem(key);

    if (!data) return null;

    const validData = isDecode ? window.atob(data) : data;
    return JSON.parse(validData);
  }

  function set({ key, value, isEncode }: LocalStorageSet): void {
    const data = JSON.stringify(value);
    const validData = isEncode ? window.btoa(data) : data;

    localStorage.setItem(key, validData);
  }

  function remove(key: string): void {
    localStorage.removeItem(key);
  }

  function reset(): void {
    localStorage.clear();
  }

  function isExist(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  const { current: ls } = useRef({ get, isExist, remove, reset, set });

  return ls;
}
