import { authAPI } from '@/api';
import {
  PayloadLoginWithGoogle,
  PayloadLoginWithPassword,
  ResponseLoginWithGoogle,
  ResponseLoginWithPassword,
} from '@/models';

export async function loginWithGoogle(
  payload: PayloadLoginWithGoogle,
): Promise<ResponseLoginWithGoogle> {
  const response = await authAPI.post<ResponseLoginWithGoogle>(
    '/v2/auth/login',
    payload,
  );

  return response?.data;
}

export async function loginWithPassword(
  payload: PayloadLoginWithPassword,
): Promise<ResponseLoginWithPassword> {
  const response = await authAPI.post<ResponseLoginWithPassword>(
    '/v2/auth/oauth-login/password',
    payload,
  );

  return response?.data;
}

export default loginWithGoogle;
