/* eslint-disable react/require-default-props */
import { RightOutlined } from '@ant-design/icons';
import { memo, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

interface CardWithActionProps {
  disabled?: boolean;
  icon: ReactNode;
  subtitle?: string;
  title?: string;
  onClick?(): void;
}

const Card = styled.div<{ disabled: boolean }>`
  align-items: center;
  background-color: ${({ disabled, theme }) => {
    return disabled ? theme.color.white5 : theme.color.white4;
  }};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  padding: 10px 16px;
`;

const CardIcon = styled.div`
  align-items: center;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

const CardContent = styled.div`
  flex-grow: 1;
  margin: 0 20px;
`;

const CardAction = styled.div<{ disabled: boolean }>`
  align-items: center;
  color: ${({ disabled, theme }) => {
    return disabled ? theme.color.black500 : theme.color.green2;
  }};
  display: inline-flex;
  font-size: 18px;
  height: 24px;
  justify-content: center;
  width: 24px;
`;

const CardTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 0;
`;

const CardSubtitle = styled.span`
  color: ${({ theme }) => theme.color.black1};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 18px;
`;

function CardWithAction({
  disabled = false,
  icon,
  subtitle = '',
  title = '',
  onClick = () => null,
}: PropsWithChildren<CardWithActionProps>) {
  return (
    <Card disabled={disabled} onClick={() => !disabled && onClick()}>
      <CardIcon>{icon}</CardIcon>

      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
      </CardContent>

      <CardAction disabled={disabled}>
        <RightOutlined />
      </CardAction>
    </Card>
  );
}

export default memo(CardWithAction);
