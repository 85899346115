import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { SuspenseFallback } from './components';
import Routers from './routers';
import { AuthProvider, SnackbarProvider } from './contexts';
import { antDTheme, styledComponentTheme } from './themes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <SnackbarProvider>
            <AuthProvider>
              <ConfigProvider theme={antDTheme}>
                <Suspense fallback={<SuspenseFallback />}>
                  <ThemeProvider theme={styledComponentTheme}>
                    <Routers />
                  </ThemeProvider>
                </Suspense>
              </ConfigProvider>
            </AuthProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
