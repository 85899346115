import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { Header, Sidebar } from '@/components';

const StyledLayout = styled(Layout)`
  min-height: 100%;

  .ant-menu-item {
    border-radius: 0;
    width: 100%;
  }

  .ant-menu-item-selected {
    box-shadow: ${(props) => `inset 3px 0 ${props.theme.color.primary}`};
  }
`;

const StyledContent = styled(Layout.Content)`
  background-color: ${({ theme }) => theme.color.white};
  padding: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: 16px;
  }
`;

function LayoutApp() {
  return (
    <StyledLayout>
      <Sidebar />

      <Layout>
        <Header />
        <StyledContent>
          <Outlet />
        </StyledContent>
      </Layout>
    </StyledLayout>
  );
}

export default LayoutApp;
