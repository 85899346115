/* eslint-disable import/no-cycle */
import axios from 'axios';

import {
  interceptConfigRequest,
  interceptErrorRequest,
  interceptErrorResponse,
  interceptSuccessResponse,
} from '.';

const vibrioAPI = axios.create({
  baseURL: import.meta.env.VITE_VIBRIO_VISION_CMS_URL,
  headers: {
    'Content-type': 'application/json',
    'X-Client-Id': import.meta.env.VITE_CLIENT_ID,
  },
});

vibrioAPI.interceptors.request.use(
  interceptConfigRequest,
  interceptErrorRequest,
);

vibrioAPI.interceptors.response.use(
  interceptSuccessResponse,
  interceptErrorResponse,
);

export default vibrioAPI;
