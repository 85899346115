import { Input } from 'antd';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

interface SearchProps {
  value: string;
  onPressSearch(search: string): void;
}

const StyledInputSearch = styled(Input.Search)`
  .ant-select-arrow {
    color: ${({ theme }) => theme.color.black};
  }

  .ant-input-group-addon {
    .ant-btn {
      border-left: 0;
    }
  }

  .ant-input-search-button {
    box-shadow: none;

    .anticon-search {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

function Search({ onPressSearch, value }: SearchProps) {
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(value);
  }, [value]);

  return (
    <StyledInputSearch
      value={search}
      placeholder="Cari di sini"
      onChange={(event) => setSearch(event.target.value)}
      onSearch={() => onPressSearch(search)}
    />
  );
}

export default Search;
