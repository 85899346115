import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/id';

dayjs.extend(dayOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.locale('id');

type IDate = string | number | Date | dayjs.Dayjs | null;

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_PAYLOAD_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY, HH:mm';
export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

export function transformToDefaultDateFormat(date: IDate = new Date()): string {
  return dayjs(date).format(DEFAULT_DATE_FORMAT);
}

export function transformToDefaultDatePayloadFormat(
  date: IDate = new Date(),
): string {
  return dayjs(date).format(DEFAULT_DATE_PAYLOAD_FORMAT);
}
