import { Tabs } from 'antd';
import styled from 'styled-components';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    &::before {
      border: 0;
    }

    .ant-tabs-ink-bar {
      height: 0;
    }
  }
`;

export default StyledTabs;
