/* eslint-disable react/require-default-props */
import { Typography } from 'antd';
import styled from 'styled-components';

interface WrapperProps {
  margin?: string;
}

interface LabelWithValueProps extends WrapperProps {
  value?: string | number;
  label: string;
}

const Label = styled(Typography.Text)`
  display: block;
`;

const Wrapper = styled.div<WrapperProps>`
  margin: ${({ margin }) => margin || '0px'};
`;

function LabelWithValue({ label, value, ...props }: LabelWithValueProps) {
  return (
    <Wrapper {...props}>
      <Label>{label}</Label>
      <Typography.Text strong>{value}</Typography.Text>
    </Wrapper>
  );
}

export default LabelWithValue;
