/* eslint-disable react/require-default-props */
import { Input, InputProps, InputRef } from 'antd';
import { forwardRef } from 'react';

import { Form } from '@/components';

interface InputReusableProps extends InputProps {
  error?: string;
  label?: string;
}

const InputReusable = forwardRef<InputRef, InputReusableProps>(
  ({ error, label, ...props }, ref) => {
    return (
      <>
        {!!label && <Form.Label htmlFor={props.id}>{label}</Form.Label>}
        <Input {...props} ref={ref} status={error ? 'error' : ''} />
        {!!error && <Form.Text type="danger">{error}</Form.Text>}
      </>
    );
  },
);

InputReusable.displayName = 'Input';

export default InputReusable;
