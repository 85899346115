import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '@/components/ProtectedRoute';

import { Layout } from '../components';

const PageLogin = lazy(() => import('@/pages/auth/login'));
const PageFAQ = lazy(() => import('@/pages/faq'));
const PageHistories = lazy(() => import('@/pages/histories'));
const PageAnnotator = lazy(() => import('@/pages/histories/annotator'));
const PageHistoryDetail = lazy(() => import('@/pages/histories/detail'));

function Routers() {
  return (
    <Routes>
      <Route path="/" element={<PageLogin />} />

      <Route path="/login" element={<PageLogin />} />

      <Route path="/" element={<Layout />}>
        <Route
          path="/faq"
          element={
            <ProtectedRoute>
              <PageFAQ />
            </ProtectedRoute>
          }
        />

        <Route path="histories">
          <Route
            index
            element={
              <ProtectedRoute>
                <PageHistories />
              </ProtectedRoute>
            }
          />

          <Route
            path=":uuid/annotator"
            element={
              <ProtectedRoute>
                <PageAnnotator />
              </ProtectedRoute>
            }
          />

          <Route
            path=":uuid/detail"
            element={
              <ProtectedRoute>
                <PageHistoryDetail />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default Routers;
