import { Layout } from 'antd';
import styled from 'styled-components';

import { MenuNavigation } from '@/components';

import SidebarDrawer from '@/components/Sidebar/SidebarDrawer';

const StyledLayoutSider = styled(Layout.Sider)`
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
`;

const SidebarBrand = styled.div`
  align-items: center;
  display: flex;
  height: 64px;

  .ant-typography {
    margin: 0;
  }

  .sidebar__logo {
    height: 30px;
    margin: 16px auto;
  }
`;

function Sidebar() {
  return (
    <StyledLayoutSider
      collapsible
      theme="light"
      width={250}
      trigger={null}
      breakpoint="lg"
      collapsedWidth="0"
    >
      <SidebarBrand>
        <img
          className="sidebar__logo"
          src="/sidebar_logo.png"
          alt="eFishery"
          loading="lazy"
        />
      </SidebarBrand>

      <MenuNavigation />
    </StyledLayoutSider>
  );
}

export default Object.assign(Sidebar, {
  Drawer: SidebarDrawer,
});
