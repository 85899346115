import { useCallback } from 'react';
import { Button, Flex, List, Typography } from 'antd';
import styled from 'styled-components';
import { CloseCircleFilled, EditFilled } from '@ant-design/icons';

import { SquareIcon } from '@/components';
import { Annotation, AnnotationBody } from '@/models';
import { vibrioObjects } from '@/constants';

type OpenSeaDragonSidebarProps = {
  onCancel(): void;
  annotations: Annotation[];
};

const Wrapper = styled.div`
  background: var(--Background-White, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
  display: flex;
  flex-direction: column;

  .flex-item {
    width: 100%;
  }

  .ant-list {
    flex: 1;
  }

  .ant-list .ant-list-item,
  .ant-list .ant-list-header {
    padding: 16px 24px;
  }

  .cta {
    padding: 16px 24px;
    border-top: 1px solid var(--Border-Regular, #d4d4d4);
  }
`;

function OpenSeaDragonSidebar({
  annotations,
  onCancel,
}: OpenSeaDragonSidebarProps) {
  const countObject = useCallback(
    (object: string): number => {
      let bodies: AnnotationBody[] = [];

      annotations.forEach(({ body }) => {
        bodies = [...bodies, ...body];
      });

      const bodiesWithSpecificObject = bodies.filter(
        (body) => body.value === object,
      );

      return bodiesWithSpecificObject.length;
    },
    [annotations],
  );

  return (
    <Wrapper>
      <List
        header={<Typography.Text strong>Label</Typography.Text>}
        dataSource={vibrioObjects}
        renderItem={(item) => (
          <List.Item>
            <Flex className="flex-item" justify="space-between" align="center">
              <Flex gap={16} align="center">
                <SquareIcon fill={item.color} width={18} height={18} />
                <Typography.Text>{item.label}</Typography.Text>
              </Flex>
              <Typography.Text strong>
                {countObject(item.value)}
              </Typography.Text>
            </Flex>
          </List.Item>
        )}
      />

      <Flex justify="center" gap="middle" align="center" className="cta">
        <Button type="default" icon={<CloseCircleFilled />} onClick={onCancel}>
          Batalkan
        </Button>
        <Button
          type="primary"
          icon={<EditFilled />}
          disabled={annotations.length === 0}
        >
          Simpan
        </Button>
      </Flex>
    </Wrapper>
  );
}

export default OpenSeaDragonSidebar;
