import { ReactNode } from 'react';
import { SnackbarProvider as NotistackSnakbarProvider } from 'notistack';

function SnackbarProvider({ children }: { children: ReactNode }) {
  return (
    <NotistackSnakbarProvider
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </NotistackSnakbarProvider>
  );
}

export default SnackbarProvider;
