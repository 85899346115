/* eslint-disable import/no-cycle */
import axios from 'axios';

import {
  interceptConfigRequest,
  interceptErrorRequest,
  interceptErrorResponse,
  interceptSuccessResponse,
} from '.';

const authAPI = axios.create({
  baseURL: import.meta.env.VITE_AUTH_SERVICE_URL,
  headers: {
    'Content-type': 'application/json',
    'X-Client-Id': import.meta.env.VITE_CLIENT_ID,
  },
});

authAPI.interceptors.request.use(interceptConfigRequest, interceptErrorRequest);

authAPI.interceptors.response.use(
  interceptSuccessResponse,
  interceptErrorResponse,
);

export default authAPI;
