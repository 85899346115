import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import colors, { Colors } from '@/themes/colors';

interface BadgeProps {
  // eslint-disable-next-line react/require-default-props
  color?: 'default' | 'successPrimary' | 'errorPrimary';
}

const fontColors: Record<string, string> = {
  default: colors.dark,
  successPrimary: colors.primary,
  errorPrimary: colors.error,
};

const StyledBadge = styled.div<BadgeProps>`
  align-items: center;
  background-color: ${({ color, theme }) =>
    theme.color[color as keyof Colors] || theme.color.grey200};
  border-radius: 8px;
  color: ${({ color }) => fontColors[color as keyof Record<string, string>]};
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 18px;
  padding: 0px 8px;
`;

function Badge({ children, color }: PropsWithChildren<BadgeProps>) {
  return <StyledBadge color={color}>{children}</StyledBadge>;
}

export default Badge;
