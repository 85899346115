import { Alert as AlertAntD, AlertProps } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

const StyledAlert = styled(AlertAntD)`
  padding: 12px;

  &.ant-alert {
    .ant-alert-content .ant-alert-message {
      font-weight: 600;
      font-size: 14px;
    }

    .ant-alert-icon {
      margin-inline-end: 8px;
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 16px;
    }

    .ant-alert-message {
      margin-bottom: 2px;
    }
  }

  &.ant-alert-success {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.white4};
    border: ${({ theme }) => `0.5px solid ${theme.color.successPrimary}`};
    border-left: ${({ theme }) =>
      `4px solid ${theme.color.primary}`} !important;

    .ant-alert-content .ant-alert-message,
    .ant-alert-content .ant-alert-description {
      color: ${({ theme }) => theme.color.successSecondary};
    }

    .ant-alert-icon {
      font-size: 16px;
      color: ${({ theme }) => theme.color.successSecondary};
    }
  }

  &.ant-alert-warning {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.warning50};
    border: ${({ theme }) => `0.5px solid ${theme.color.warning200}`};
    border-left: ${({ theme }) =>
      `4px solid ${theme.color.warning700}`} !important;

    .ant-alert-content .ant-alert-message,
    .ant-alert-content .ant-alert-description {
      color: ${({ theme }) => theme.color.warning700};
    }

    .ant-alert-icon {
      font-size: 16px;
      color: ${({ theme }) => theme.color.warning700};
    }
  }

  &.ant-alert-error {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.error50};
    border: ${({ theme }) => `0.5px solid ${theme.color.error200}`};
    border-left: ${({ theme }) => `4px solid ${theme.color.error}`} !important;

    .ant-alert-content .ant-alert-message,
    .ant-alert-content .ant-alert-description {
      color: ${({ theme }) => theme.color.error};
    }

    .ant-alert-icon {
      font-size: 16px;
      color: ${({ theme }) => theme.color.error};
    }
  }
`;

function Alert({
  type = 'error',
  showIcon = true,
  description = 'Error',
  action = null,
  ...props
}: AlertProps) {
  return (
    <StyledAlert
      type={type}
      action={action}
      showIcon={showIcon}
      description={description}
      {...props}
    />
  );
}

export default memo(Alert);
