import { Steps } from 'antd';
import styled from 'styled-components';

const StyledSteps = styled(Steps)`
  margin: 24px 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-bottom: 0;
  }

  .ant-steps-item-title {
    font-size: 14px;
  }
`;

export default StyledSteps;
