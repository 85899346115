import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import OpenSeaDragon from 'openseadragon';
import styled from 'styled-components';
import * as Annotorious from '@recogito/annotorious-openseadragon';

import { Annotation, Annotator } from '@/models';
import { formatterAnnotatorColor } from '@/helpers';
import {
  OpenSeaDragonCanvas,
  OpenSeaDragonSidebar,
  OpenSeaDragonToolbar,
  OpenSeaDragonWrapper,
  WidgetAnnotator,
} from '@/components';

import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';

type OpenSeaDragonViewerProps = {
  onCancel(): void;
  image: {
    type: string;
    url: string;
  };
};

const OpenSeaDragonLayout = styled.div`
  height: 100%;
  flex: 1;

  > .ant-row {
    height: 100%;
  }
`;

const Caption = styled.div`
  padding: 16px 0px;
`;

function OpenSeaDragonViewer({ image, onCancel }: OpenSeaDragonViewerProps) {
  const [tool, setTool] = useState('rect');
  const [annotator, setAnnotator] = useState<Annotator | null>(null);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);

  const toggleTool = () => {
    if (tool === 'rect') {
      setTool('polygon');
      annotator?.setDrawingTool('polygon');
    } else {
      setTool('rect');
      annotator?.setDrawingTool('rect');
    }
  };

  const initOpenSeaDragon = useCallback(() => {
    const viewer = OpenSeaDragon({
      id: 'openSeaDragon',
      prefixUrl: 'openseadragon-images/',
      animationTime: 0.5,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: 2,
      minZoomLevel: 1,
      visibilityRatio: 1,
      zoomPerScroll: 2,
      tileSources: image,
      autoHideControls: false,
      zoomInButton: 'osd-zoom-in',
      zoomOutButton: 'osd-zoom-out',
      homeButton: 'osd-home',
      fullPageButton: 'osd-fulls',
    });

    const config = {
      hotkey: {
        key: 'Shift',
      },
      formatters: formatterAnnotatorColor,
      widgets: [{ widget: WidgetAnnotator, force: 'plainjs' }],
    };

    let annotate;
    if (import.meta.env.PROD) {
      annotate = Annotorious.default(viewer, config);
    } else {
      annotate = Annotorious(viewer, config);
    }

    setAnnotator(annotate);

    return viewer;
  }, [image]);

  const initAnnotations = useCallback(() => {
    annotator?.on('createAnnotation', (annotation: Annotation) => {
      const newAnnotations = [...annotations, annotation];
      setAnnotations(newAnnotations);
    });

    annotator?.on('updateAnnotation', (annotation, previous) => {
      const updatedAnnotations = annotations.map((item) =>
        item.id === previous?.id ? { ...item, ...annotation } : item,
      );

      setAnnotations(updatedAnnotations);
    });

    annotator?.on('deleteAnnotation', (annotation) => {
      const annotationsFilter = annotations.filter(
        ({ id }) => id !== annotation.id,
      );

      setAnnotations(annotationsFilter);
    });
  }, [annotations, annotator]);

  useEffect(() => {
    if (image && annotator) {
      initAnnotations();
    }
  }, [annotator, image, initAnnotations]);

  useEffect(() => {
    const viewer = initOpenSeaDragon();
    return () => {
      viewer?.destroy();
    };
  }, [image, initOpenSeaDragon]);

  return (
    <>
      <Caption>
        <Typography.Text type="secondary" italic>
          * Tahan tombol <b>SHIFT</b> sambil mengklik dan menyeret mouse untuk
          membuat anotasi baru.
        </Typography.Text>
      </Caption>

      <OpenSeaDragonLayout>
        <Row>
          <Col flex="auto">
            <OpenSeaDragonWrapper>
              <OpenSeaDragonToolbar toggle={toggleTool} tool={tool} />
              <OpenSeaDragonCanvas id="openSeaDragon" />
            </OpenSeaDragonWrapper>
          </Col>

          <Col flex="220px">
            <OpenSeaDragonSidebar
              onCancel={onCancel}
              annotations={annotations}
            />
          </Col>
        </Row>
      </OpenSeaDragonLayout>
    </>
  );
}

export default OpenSeaDragonViewer;
