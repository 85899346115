export type Breakpoints = {
  mobile: string;
  tablet: string;
  desktop: string;
  highResDesktop: string;
};

const breakpoints: Breakpoints = {
  mobile: '600px',
  tablet: '768px',
  desktop: '1200px',
  highResDesktop: '1600px',
};

export default breakpoints;
