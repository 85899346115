import sha from 'sha.js';

export const generateRandomString = (length: number): string => {
  const charset = import.meta.env.VITE_CHARSET_RANDOM_STRING as string;
  let randomString = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }

  return randomString;
};

export const generateCodeChallenge = (codeVerifier: string): string => {
  const codeChallenge = sha('sha256')
    .update(codeVerifier)
    .digest('base64')
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

  return codeChallenge;
};

export function getInitials(value = ''): string {
  if (!value) return '-';

  const words = value.split(' ').filter((word) => !!word);

  if (words.length < 1) return '-';

  const haveOnlyOneWord = words.length < 2;
  const [firstWord] = words;
  const [firstCharInFirstWord] = firstWord.split('');

  if (haveOnlyOneWord) return firstCharInFirstWord;

  const lastWord = words[words.length - 1];
  const [firstCharInLastWord] = lastWord.split('');

  return `${firstCharInFirstWord}${firstCharInLastWord}`;
}

export function printWithDefaultValue(
  text?: unknown,
  defaultValue = '-',
): string {
  const invalidTexts = [null, undefined, ''];
  if (invalidTexts.includes(text?.toString())) return defaultValue;
  return `${text}`;
}
