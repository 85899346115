import { Typography } from 'antd';
import styled from 'styled-components';

const Form = styled.form``;

const FormLabel = styled.label`
  color: ${({ theme }) => theme.color.black};
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5;
  margin-bottom: 8px;
`;

const FormText = styled(Typography.Text)`
  font-size: 10px;
  margin-left: 8px;
`;

export default Object.assign(Form, {
  Label: FormLabel,
  Text: FormText,
});
