import { Spin } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px auto;
`;

function Loader() {
  return (
    <Wrapper>
      <Spin size="large" />
    </Wrapper>
  );
}

export default Loader;
