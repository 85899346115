import styled from 'styled-components';

export const OpenSeaDragonCanvas = styled.div`
  width: 100%;
  height: 466px;
`;

export const OpenSeaDragonWrapper = styled.div`
  height: 100%;
  padding: 16px;
  border: 1px solid #efefef;
  position: relative;
  background-color: #f4f4f4 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  #osd-toolbar {
    background-color: red;
    position: absolute;
    z-index: 10;
    bottom: 16px;
    border-radius: 360px;
    background: var(--Background-Light, #fafafa);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 8px;
    display: inline-flex;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 16px;

    .osd-buttons {
      cursor: pointer;
      color: #737373;
      height: 32px;
      width: 32px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    .osd-buttons:hover,
    .osd-buttons-active {
      color: #fff;
      background-color: #038767;
    }
  }

  .widget {
    padding: 16px;
  }

  .select-widget,
  .form-select {
    width: 100%;
  }

  select {
    display: inline-block;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    padding: 8px;

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .form-select {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
  }

  .form-select:focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, green 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
  }

  .r6o-editor .r6o-footer {
    padding: 16px 8px !important;
  }

  .r6o-btn {
    border-radius: 6px;
    background-color: var(--Background-Brand, #038767);
  }

  .r6o-btn.outline {
    background-color: transparent !important;
    border: 1px solid var(--Background-Brand, #038767);
    color: var(--Background-Brand, #038767);
  }

  .r6o-editor .r6o-footer .r6o-btn.delete-annotation {
    color: var(--Background-Brand, #038767);
    top: 19px;
    left: 9px;
  }

  .r6o-editor .r6o-footer .r6o-btn.delete-annotation:hover {
    color: #fff;
  }

  svg.a9s-annotationlayer .a9s-selection .a9s-inner,
  svg.a9s-annotationlayer .a9s-annotation .a9s-inner {
    stroke-width: 2;
    stroke: white;
  }

  svg.a9s-annotationlayer .a9s-annotation.editable:hover .a9s-inner,
  .a9s-annotation .a9s-inner {
    fill: var(--background-black-opacity-70, rgba(0, 0, 0, 0.7));
  }

  svg.a9s-annotationlayer .a9s-handle .a9s-handle-inner {
    fill: white;
    stroke: white;
  }

  svg.a9s-annotationlayer .a9s-annotation.bacteria .a9s-outer {
    stroke: #be123c;
    stroke-width: 2;
    fill: rgba(190, 18, 60, 0.45);
  }

  svg.a9s-annotationlayer .a9s-annotation.vibrio-green .a9s-outer {
    stroke: #3f6212;
    stroke-width: 2;
    fill: rgba(101, 163, 13, 0.45);
  }

  svg.a9s-annotationlayer .a9s-annotation.vibrio-yellow .a9s-outer {
    stroke: #d97706;
    stroke-width: 2;
    fill: rgba(251, 191, 36, 0.45);
  }

  svg.a9s-annotationlayer .a9s-annotation.vibrio-yellow .a9s-inner,
  svg.a9s-annotationlayer .a9s-annotation.vibrio-green .a9s-inner,
  svg.a9s-annotationlayer .a9s-annotation.bacteria .a9s-inner {
    fill: transparent;
    stroke: none;
  }
`;
