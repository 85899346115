import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, Col, Row } from 'antd';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledArrowLeftOutlined = styled(ArrowLeftOutlined)`
  margin-top: 4px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.primary};
`;

const StyledRow = styled(Row)`
  align-items: center;
  display: flex;
  margin-bottom: 32px;
`;

interface TitleContentProps {
  to: string;
}

function TitleContent({ children, to }: PropsWithChildren<TitleContentProps>) {
  return (
    <StyledRow>
      <Col flex="30px">
        <Link to={to}>
          <StyledArrowLeftOutlined />
        </Link>
      </Col>

      <Col flex="auto">
        <Typography.Title level={4}>{children}</Typography.Title>
      </Col>
    </StyledRow>
  );
}

export default TitleContent;
