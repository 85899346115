import { LogoutOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import styled from 'styled-components';

import { Sidebar } from '@/components';
import { useAuth } from '@/contexts';

const StyledMenu = styled(Menu)`
  border: 0 !important;
`;

const StyledHeader = styled(Layout.Header)`
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 0 16px;
	boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)
`;

function Header() {
  const auth = useAuth();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <StyledHeader style={{ background: colorBgContainer }}>
      <Sidebar.Drawer />

      <StyledMenu
        onClick={auth.signOut}
        items={[
          {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: 'Logout',
          },
        ]}
      />
    </StyledHeader>
  );
}

export default Header;
