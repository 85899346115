/* eslint-disable react/require-default-props */
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { forwardRef } from 'react';

import { Form } from '@/components';

interface TextAreaReusableProps extends TextAreaProps {
  error?: string;
  label?: string;
}

const TextAreaReusable = forwardRef<TextAreaRef, TextAreaReusableProps>(
  ({ error, label, ...props }, ref) => {
    return (
      <>
        {!!label && <Form.Label htmlFor={props.id}>{label}</Form.Label>}
        <Input.TextArea {...props} ref={ref} status={error ? 'error' : ''} />
        {!!error && <Form.Text type="danger">{error}</Form.Text>}
      </>
    );
  },
);

TextAreaReusable.displayName = 'TextArea';

export default TextAreaReusable;
