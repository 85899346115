import { DesktopOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function MenuNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  const selectedKey = useMemo(() => {
    const pathnames = location.pathname.split('/');
    const validPathnames = pathnames.splice(0, 2);

    return validPathnames.join('/');
  }, [location.pathname]);

  return (
    <Menu
      mode="inline"
      theme="light"
      defaultSelectedKeys={['/']}
      selectedKeys={[selectedKey]}
      items={[
        {
          key: '/histories',
          label: 'Vibrio Vision',
          icon: <DesktopOutlined />,
        },
        {
          key: '/faq',
          label: 'FAQ',
          icon: <QuestionCircleOutlined />,
        },
      ]}
      onClick={(menu) => navigate(menu.key)}
    />
  );
}

export default MenuNavigation;
