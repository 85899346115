import { Annotation } from '@/models';

export const formatterAnnotatorColor = ({
  bodies,
}: Annotation): string | undefined => {
  const bacteria = bodies.find((body) => {
    return body.purpose === 'highlighting' && body.value === 'bacteria';
  });

  if (bacteria) return bacteria?.value;

  const vibrioYellow = bodies.find((body) => {
    return body.purpose === 'highlighting' && body.value === 'vibrio-yellow';
  });

  if (vibrioYellow) return vibrioYellow?.value;

  const vibrioGreen = bodies.find((body) => {
    return body.purpose === 'highlighting' && body.value === 'vibrio-green';
  });

  if (vibrioGreen) return vibrioGreen?.value;

  return '';
};

export default formatterAnnotatorColor;
