export enum VibrioTypeValue {
  All = 'all',
  Vibrio = 'vibrio-counter',
  Bacteria = 'bacteria-counter',
}

export enum VibrioTypeLabel {
  All = 'Semua',
  Vibrio = 'Vibrio',
  Bacteria = 'Bacteria',
}

export enum VibrioTypeColor {
  All = 'Semua',
  Vibrio = 'Vibrio',
  Bacteria = 'Bacteria',
}

export enum VibrioObjectValue {
  Bacteria = 'bacteria',
  VibrioGreen = 'vibrio-green',
  VibrioYellow = 'vibrio-yellow',
}

export enum VibrioObjectLabel {
  Bacteria = 'Bacteria',
  VibrioGreen = 'Vibrio Green',
  VibrioYellow = 'Vibrio Yellow',
}

export enum VibrioObjectColor {
  Bacteria = '#F43F5E',
  VibrioGreen = '#65A30D',
  VibrioYellow = '#FCD34D',
}
