import {
  VibrioObjectColor,
  VibrioObjectLabel,
  VibrioObjectValue,
  VibrioTypeLabel,
  VibrioTypeValue,
} from '@/enums';

export const vibrioTypes = [
  {
    label: VibrioTypeLabel.All,
    value: VibrioTypeValue.All,
  },
  {
    label: VibrioTypeLabel.Bacteria,
    value: VibrioTypeValue.Bacteria,
  },
  {
    label: VibrioTypeLabel.Vibrio,
    value: VibrioTypeValue.Vibrio,
  },
];

export const vibrioObjects = [
  {
    label: VibrioObjectLabel.VibrioGreen,
    value: VibrioObjectValue.VibrioGreen,
    color: VibrioObjectColor.VibrioGreen,
  },
  {
    label: VibrioObjectLabel.VibrioYellow,
    value: VibrioObjectValue.VibrioYellow,
    color: VibrioObjectColor.VibrioYellow,
  },
  {
    label: VibrioObjectLabel.Bacteria,
    value: VibrioObjectValue.Bacteria,
    color: VibrioObjectColor.Bacteria,
  },
];
