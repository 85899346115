/* eslint-disable react/require-default-props */
import { RefSelectProps, Select, SelectProps } from 'antd';
import { forwardRef } from 'react';

import { Form } from '@/components';

interface SelectReusableProps extends SelectProps {
  error?: string;
  label?: string;
}

const SelectReusable = forwardRef<RefSelectProps, SelectReusableProps>(
  ({ error, label, ...props }, ref) => {
    return (
      <>
        {!!label && <Form.Label htmlFor={props.id}>{label}</Form.Label>}
        <Select {...props} ref={ref} status={error ? 'error' : ''} />
        {!!error && <Form.Text type="danger">{error}</Form.Text>}
      </>
    );
  },
);

SelectReusable.displayName = 'Select';

export default SelectReusable;
