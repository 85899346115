/* eslint-disable react/require-default-props */
import { Modal, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

interface WrapperProps {
  margin?: string;
}

interface LabelWithImageProps extends WrapperProps {
  imageURL?: string;
  label: string;
  width?: number;
}

const Wrapper = styled.div<WrapperProps>`
  margin: ${({ margin }) => margin || '0px'};
`;

const Label = styled(Typography.Text)`
  display: block;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 32px;
  }

  .ant-modal-close {
    top: 10px;
    right: 10px;
  }
`;

const WrapperImage = styled.div`
  border-radius: 4px;
  width: 48px;
  height: 48px;
  padding: 4px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${({ theme: { color } }) => `0.5px solid ${color.black}`};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

function LabelWithImage({ label, imageURL, ...props }: LabelWithImageProps) {
  const [isPreviewOpen, setPreviewOpen] = useState(false);

  return (
    <Wrapper {...props}>
      <Label>{label}</Label>

      <WrapperImage role="button" onClick={() => setPreviewOpen(true)}>
        <Image
          alt="Label With Image"
          src={imageURL || '/placeholder-image.png'}
        />
      </WrapperImage>

      <StyledModal
        footer={null}
        open={isPreviewOpen}
        onCancel={() => setPreviewOpen(false)}
      >
        <Image alt="preview" src={imageURL || '/placeholder-image.png'} />
      </StyledModal>
    </Wrapper>
  );
}

export default LabelWithImage;
