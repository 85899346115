import {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  useCallback,
} from 'react';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { loginWithGoogle, loginWithPassword } from '@/services';
import { USER_TOKEN } from '@/constants/storage';
import useLocalStorage from '@/hooks/useLocalStorage';
import { PayloadLoginWithGoogle, PayloadLoginWithPassword } from '@/models';
import { generateCodeChallenge, generateRandomString } from '@/utils';

export type User = {
  token: string;
  email: string;
};

type LoadingType = {
  withGoogle: boolean;
  withPassword: boolean;
};

type AuthContextProps = {
  user: User | null;
  loading: LoadingType;
  authCode: string;
  signOut: () => void;
  handleGoogleLogin: () => void;
  setAuthCode: React.Dispatch<React.SetStateAction<string>>;
  signIn: (
    payload: PayloadLoginWithGoogle | PayloadLoginWithPassword,
    type: 'withGoogle' | 'withPassword',
  ) => Promise<void>;
};

const authServiceURL = import.meta.env.VITE_AUTH_SERVICE_URL;
const clientID = import.meta.env.VITE_AUTH_CLIENT_ID;
const VERIFIER_CODE_STORAGE_KEY = 'vibrio-verifier-code';

export const AuthContext = createContext<Partial<AuthContextProps>>({});

export function AuthProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const ls = useLocalStorage();
  const { enqueueSnackbar } = useSnackbar();

  const from = location.state?.from?.pathname || '/';

  const user = (ls.get(USER_TOKEN, true) as User) || null;

  const [authCode, setAuthCode] = useState('');
  const [loading, setLoading] = useState<LoadingType>({
    withGoogle: false,
    withPassword: false,
  });

  const signIn = useCallback(
    async (
      payload: PayloadLoginWithGoogle | PayloadLoginWithPassword,
      type: 'withGoogle' | 'withPassword',
    ) => {
      try {
        setLoading((prevState) => ({
          ...prevState,
          [type]: true,
        }));

        const response =
          type === 'withGoogle'
            ? await loginWithGoogle(payload as PayloadLoginWithGoogle)
            : await loginWithPassword(payload as PayloadLoginWithPassword);

        setAuthCode('');
        setLoading((prevState) => ({
          ...prevState,
          [type]: false,
        }));

        ls.remove(VERIFIER_CODE_STORAGE_KEY);
        ls.set({
          key: USER_TOKEN,
          value: response.data,
          isEncode: true,
        });

        enqueueSnackbar('Berhasil login', { variant: 'success' });
        navigate(from, { replace: true });
      } catch (error) {
        const { message } = error as AxiosError;
        enqueueSnackbar(message || 'Gagal login', { variant: 'error' });
      } finally {
        setLoading((prevState) => ({
          ...prevState,
          [type]: false,
        }));
      }
    },
    [enqueueSnackbar, from, ls, navigate],
  );

  const signOut = useCallback(() => {
    ls.remove(USER_TOKEN);
    enqueueSnackbar('Berhasil logout', { variant: 'success' });
    navigate('/');
  }, [enqueueSnackbar, ls, navigate]);

  const handleGoogleLogin = useCallback(async () => {
    const codeVerifier = generateRandomString(43);
    const codeChallenge = generateCodeChallenge(codeVerifier);

    const params = new URLSearchParams({
      code_challenge: codeChallenge,
      redirect_url: `${window.location.origin}/login`,
    });

    ls.set({ key: VERIFIER_CODE_STORAGE_KEY, value: codeVerifier });

    setLoading((prevState) => ({
      ...prevState,
      withGoogle: true,
    }));

    window.location.href = `${authServiceURL}/v2/auth/oauth-login/${clientID}?${params.toString()}`;
  }, [ls]);

  const value = useMemo(
    () => ({
      user,
      loading,
      authCode,
      signIn,
      signOut,
      setAuthCode,
      handleGoogleLogin,
    }),
    [authCode, handleGoogleLogin, loading, signIn, signOut, user],
  );

  return (
    <AuthContext.Provider value={value}>
      {children as ReactNode}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
