import { Button, Divider } from 'antd';
import {
  BorderOutlined,
  HomeOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  RadiusSettingOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

type OpenSeaDragonToolbarProps = {
  tool: string;
  toggle(): void;
};

const StyledDivider = styled(Divider)`
  height: 32px;
  margin-inline: 0px;
  border-inline-start: 1px solid #d4d4d4;
`;

function OpenSeaDragonToolbar({ toggle, tool }: OpenSeaDragonToolbarProps) {
  return (
    <div id="osd-toolbar">
      <Button id="osd-home" type="text" className="osd-buttons">
        <HomeOutlined />
      </Button>

      <Button
        type="text"
        title="Rectangle"
        className={`osd-buttons ${tool === 'rect' ? 'osd-buttons-active' : ''}`}
        onClick={toggle}
      >
        <BorderOutlined />
      </Button>

      <Button
        type="text"
        title="Polygon"
        onClick={toggle}
        className={`osd-buttons ${
          tool === 'polygon' ? 'osd-buttons-active' : ''
        }`}
      >
        <RadiusSettingOutlined />
      </Button>

      <StyledDivider type="vertical" />

      <Button id="osd-zoom-in" type="text" className="osd-buttons">
        <PlusSquareOutlined />
      </Button>

      <Button id="osd-zoom-out" type="text" className="osd-buttons">
        <MinusSquareOutlined />
      </Button>
    </div>
  );
}

export default OpenSeaDragonToolbar;
